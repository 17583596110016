import React from 'react';
import {Subscription} from '../utilities/subscription';

const subscription = process.env.RAZZLE_APP_SUBSCRIPTION;

export const SubscriptionStateContext = React.createContext({});

export const SubscriptionStateProvider = React.memo(({children}) => {
    const [state] = React.useState(() => new Subscription(subscription, {reconnect: true, lazy: true}));
    return (
        <SubscriptionStateContext.Provider value={state}>
            {children}
        </SubscriptionStateContext.Provider>
    );
});

export const useSubscriptionState = () => React.useContext(SubscriptionStateContext);