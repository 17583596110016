import {graphql} from 'react-relay/hooks';

export const graphqlWhoamiNextQuery = graphql`
  query graphqlWhoamiNextQuery {
      whoamiNext {
          id
          username
          email
      }
  }
`;