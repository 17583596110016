/**
 * @generated SignedSource<<ed3df6410044e1d8c1b48c1f316999af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Endpoint",
    "kind": "LinkedField",
    "name": "updateEndpointTags",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tags",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "step8OperatorInterfaceEndpointTagsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "step8OperatorInterfaceEndpointTagsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6c2f21d8df517365d4ce4d92e6aa87fd",
    "id": null,
    "metadata": {},
    "name": "step8OperatorInterfaceEndpointTagsMutation",
    "operationKind": "mutation",
    "text": "mutation step8OperatorInterfaceEndpointTagsMutation(\n  $request: UpdateEndpointTagsRequestInput\n) {\n  updateEndpointTags(request: $request) {\n    id\n    tags\n    version\n  }\n}\n"
  }
};
})();

node.hash = "9cc09d1b2fcc543a718cdd7314d3c895";

module.exports = node;
