/**
 * @generated SignedSource<<1d1b972c5504017491c46d9ea5c53194>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "kind": "ScalarField",
    "name": "mmCreateReport",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "step8OperatorInterfaceMmCreateReportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "step8OperatorInterfaceMmCreateReportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ce812b304dc02b9905faa8002aa97a15",
    "id": null,
    "metadata": {},
    "name": "step8OperatorInterfaceMmCreateReportMutation",
    "operationKind": "mutation",
    "text": "mutation step8OperatorInterfaceMmCreateReportMutation(\n  $request: MmReportRequestInput\n) {\n  mmCreateReport(request: $request)\n}\n"
  }
};
})();

node.hash = "471d0eccd6aa02ae7f71e49a325fed2e";

module.exports = node;
