import React from 'react';

export default React.memo((e) => {
  return (
    <div>
      <h1>Application Error</h1>
      <pre style={{whiteSpace: 'pre-wrap'}}>{e.error.toString()}</pre>
    </div>
  );
});

