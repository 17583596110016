import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import StepsProgress from './steps-progress';
import Step3 from './step-3';
import Step5 from './step-5';
import Step51 from './step-5-1';
import Step52 from './step-5-2';
import Step8 from './step-8';
import './mm.scss';
import Redirect from '../redirect';
import searchFilter from '../../utilities/search-filter';
import Complete from './complete';
import Browser from '../browser';
import CheckReportStatus from './check-report-status';
import {ErrorMessagesProvider} from '../error-messages/error-messages-context';
import ErrorMessages from '../error-messages/error-messages';

export default React.memo(() =>{
    const location = useLocation();
    return(
        <Routes>
            <Route path='/' element={<Browser><ErrorMessagesProvider><CheckReportStatus/><ErrorMessages/></ErrorMessagesProvider></Browser>}>
                <Route index element={<Redirect {...{to: `step-2${searchFilter(location.search)}`, replace: true}}/>}/>
                <Route path='/step-2' element={<StepsProgress/>}/>
                <Route path='/step-3' element={<Step3/>}/>
                <Route path='/step-4' element={<StepsProgress/>}/>
                <Route path='/step-5' element={<Step5/>}/>
                <Route path='/step-5-1' element={<Step51/>}/>
                <Route path='/step-5-2' element={<Step52/>}/>
                <Route path='/step-7' element={<StepsProgress/>}/>
                <Route path='/step-8' element={<Step8/>}/>
                <Route path='/complete' element={<Complete/>}/>
            </Route>
        </Routes>
    )
});
