import Button, {RouterLinkButton} from '../button/button';
import {Link, useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import Lock from '../../assets/icons0/lock-unlocked-01.svg';
import ArrowLeft from '../../assets/icons0/arrow-left.svg';
import MinusSvg from '../../assets/icons0/minus.svg';
import PlusSvg from '../../assets/icons0/plus.svg';
import Close from '../../assets/icons/x.svg';
import DotsVerticalSvg from '../../assets/icons/dots-vertical.svg';
import StepsBar from './steps-bar';
import moment from 'moment';
import PlusCircleIcon from '../../assets/icons0/plus-circle.svg';
import {useForm} from 'react-hook-form';
import useQuery from '../../utilities/use-query';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {graphqlOperatorInterfaceServiceByIdQuery} from './graphql';
import Redirect from '../redirect';
import searchFilter from '../../utilities/search-filter';
import {useOperatorDispatch, useOperatorState} from './operator-interface';
import getImageUrl from './get-image-url';
import {isNullOrUndefined} from '../../utilities/utility';
import i18n from '../../utilities/i18n';
import {FormattedMessage} from 'react-intl';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const Step52 = () => {
  const {serviceId = serviceIdEx, endpointId, locale = 'uk'} = useQuery();
  const location = useLocation();
  const {serviceById} = useLazyLoadQuery(graphqlOperatorInterfaceServiceByIdQuery, {id: serviceId});
  const selected = serviceById.endpoints.find((item) => item.id == endpointId);
  if (!endpointId || !selected) {
    return <Redirect {...{to: `../step-5${searchFilter(location.search)}`, replace: true}}/>
  }
  const navigate = useNavigate();
  const state = useOperatorState()[serviceId];
  const selectedEndpointState = state.endpoints[selected.id] || {
    quantity: selected.quantity
  };
  const [quantity, setQuantity] = React.useState(selectedEndpointState.income || 0);
  const img = getImageUrl(selected.details);
  const refPhoto = React.useRef();
  const refAct = React.useRef();
  const initalExpirationDate = selected.end;
  const expirationDate = !isNullOrUndefined(selectedEndpointState.income) ? selectedEndpointState.end : '';
  const {register, watch} = useForm({
    defaultValues: {
      date: expirationDate ? moment(expirationDate).format('YYYY-MM-DD') : ''
    }
  });
  const dateValue = watch('date');
  const dispatch = useOperatorDispatch();
  React.useEffect(() => {
    if (!refPhoto.current || !refAct.current) return;
    const photo = refPhoto.current.getBoundingClientRect();
    const act = refAct.current.getBoundingClientRect();
    refPhoto.current.style.height = `${window.innerHeight - photo.top - act.height}px`;
  }, [refAct, refPhoto]);

  const updateEndpoint = (e) => {
    dispatch({
      type: 'update-endpoint',
      payload: {
        serviceId,
        endpoint: {
          ...selectedEndpointState,
          id: selected.id,
          income: quantity,
          end: dateValue ? moment(dateValue, 'YYYY-MM-DD').endOf('day').toISOString() : initalExpirationDate ? initalExpirationDate : null
        }
      }
    });
    navigate(`../step-5${searchFilter(location.search)}`);
  };

  const removeEndpoint = (e) => {
    dispatch({
      type: 'update-endpoint',
      payload: {
        serviceId,
        endpoint: {
          ...selectedEndpointState,
          id: selected.id,
          income: null,
          end: selected && selected.end
        }
      }
    });
    navigate(`../step-5${searchFilter(location.search)}`);
  };
  const [apply, applying] = useMutation(
    graphql`
        mutation step52WebhookServiceQuery($request: WebhookServiceRequestInput) {
            webhookService(request: $request) {
                id
            }
        }
  `);
  const open = () => {
    apply({
        variables: {
            request: {
                id: serviceById.id,
                version: serviceById.version,
                event: 'service.open'
            }
        },
        onCompleted: () => {},
        onError: () => {}
    });
  };

  return (<>
    <div className='mm-header background-color-gray-50'>
      <div className='lock-button' onClick={() => !applying && open()}>
        <Lock className='display-block color-gray-500 height-1dot25rem'/>   
      </div>
      <StepsBar/>
      <Link to={`../step-4${searchFilter(location.search)}`}>
        <ArrowLeft className='display-block color-gray-500 height-1dot5rem'/>  
      </Link>
    </div>
    <div className='fulfill-title display-flex border-bottom justify-content-space-between align-items-center'>
      <div className='text-xl medium color-gray-900'>
        {!isNullOrUndefined(selectedEndpointState.income) ? <FormattedMessage defaultMessage='Edit'/> : <FormattedMessage defaultMessage='Add'/>} <FormattedMessage defaultMessage='position'/>
      </div>
      <div>
        <Link to={`../step-5${searchFilter(location.search)}`}>
          <Close className='height-2dot5rem width-2dot5rem padding-0dot625rem display-block color-gray-700'/> 
        </Link>
      </div>
    </div>
    <div className='display-flex flex-direction-column'>
      {/* <div className='padding-top-1dot5rem padding-left-1dot25rem padding-right-1dot25rem'>
        <div {...{ref: refPhoto}}>
            <img src={img || 'https://ticket.demo.impulse.ottry.com/mm/Frame12.jpg'} className='object-fit-contain width-100percent height-100percent'/>
        </div>
      </div> */}
      <div {...{ref: refAct}} className='padding-top-1dot5rem'>
        <div className='item-name-and-description'>
          <h2 className='color-gray-900 medium text-lg'>
            {i18n(selected, 'name', locale)}
          </h2>
          
            <div className='color-gray-500 regular text-sm margin-top-0dot25rem display-flex'>
              {(!!dateValue || !!initalExpirationDate) && <span className='padding-right-0dot375rem'><FormattedMessage defaultMessage='Expires'/></span>}
              <div className='position-relative'>
                {(!!dateValue || !!initalExpirationDate) && <span className='text-decoration-underline'>{dateValue ? moment(dateValue).format('DD-MM-YYYY') : moment(initalExpirationDate).format('DD-MM-YYYY')}</span>}
                {!dateValue && !initalExpirationDate && 
                  <div className='display-flex align-items-center'>
                    <PlusCircleIcon className='height-1dot25rem width-1dot25rem margin-right-0dot5rem'/>
                    <span><FormattedMessage defaultMessage='Add expiration date'/></span>
                  </div>
                }
                <input id='date' {...register('date')} className='opacity-0 position-absolute bottom-0 top-0 left-0 right-0 width-100percent input-date-hidden z-index-1' type='date'/>
              </div>
            </div>
          <DotsVerticalSvg className='more-button display-block color-gray-400 height-1dot25rem width-1dot25rem'/> 
        </div>
        <div className='button-block-padding'>
          <div className ='itemQuantitySelectorBlock display-flex'>
            <Button
              {...{ 
                size: 'xl',
                clickHandler: () => setQuantity(prev => prev <= 1 ? 1 : prev -1),
                isLoading: false,
                fluid: 'always',
                disabled: false
              }}
            >
              <MinusSvg className='minus-icon'/> 
            </Button>
            <input id='filter'
              name='filter'
              type='text'
              value={quantity}
              onChange={() => setQuantity(quantity)}
              className='form-row__input color-gray-700 medium background-color-gray-50'
              placeholder=''
              readOnly
            />
            <Button
              {...{ 
                size: 'xl',
                clickHandler: () => setQuantity(prev => prev + 1),
                isLoading: false,
                fluid: 'always',
                disabled: false
              }}
            >
              <PlusSvg className='minus-icon'/> 
            </Button>
          </div>
          <div className='grid-button-block'>
            <Button
              {...{
                color: 'white-gradient',
                size: 'md',
                clickHandler: () => setQuantity(prev => prev + 2),
              }}
            >
              +2
            </Button>
            <Button
              {...{
                color: 'white-gradient',
                size: 'md',
                clickHandler: () => setQuantity(prev => prev + 3),
              }}
            >
              +3
            </Button>
            <Button
              {...{
                color: 'white-gradient',
                size: 'md',
                clickHandler: () => setQuantity(prev => prev + 5),
              }}
            >
              +5
            </Button>
            <Button
              {...{
                color: 'white-gradient',
                size: 'md',
                clickHandler: () => setQuantity(prev => prev + 10),
              }}
            >
              +10
            </Button>
          </div>
          <div className='display-block margin-bottom-0dot75rem'>
            <Button
              {...{
                color: 'blue-gradient',
                size: 'xl',
                clickHandler: updateEndpoint,
                isLoading: false,
                fluid: 'always',
                disabled: !quantity
              }}
            >
              {!isNullOrUndefined(selectedEndpointState.income) ? <FormattedMessage defaultMessage='Edit'/> : <FormattedMessage defaultMessage='Add'/>} <FormattedMessage defaultMessage='position'/>
            </Button>
          </div>
          {!isNullOrUndefined(selectedEndpointState.income) && 
            <div className='display-block margin-bottom-0dot75rem'>
              <Button
                {...{
                  color: 'secondary-error',
                  size: 'xl',
                  clickHandler: removeEndpoint,
                  isLoading: false,
                  fluid: 'always'
                }}
              >
                <FormattedMessage defaultMessage='Remove'/>
              </Button>
            </div>
          }
          <RouterLinkButton
            {...{
              size: 'xl',
              to: `../step-5${searchFilter(location.search)}`,
              isLoading: false,
              fluid: 'always',
              disabled: false,
              additionalClasses: 'cancelButton'
            }}
          >
            <span className='color-gray-500'><FormattedMessage defaultMessage='Cancel'/></span>
          </RouterLinkButton>
        </div>
      </div>
    </div>
  </>);
}

export default React.memo(Step52);