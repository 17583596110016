import React from 'react';
import useHydrated from '../utilities/use-hydrated';

export default React.memo(({children, fallback}) => {
  const hydrated = useHydrated();
  return (
    <>
      {hydrated ? <>{children}</> : (fallback ? <>{fallback}</> : <></>)}
    </>
  );
});

