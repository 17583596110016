import React from 'react';
import {useNavigate} from 'react-router-dom';

export default React.memo(({to, replace, state, relative}) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate(to, {replace, state, relative});
  }, []);
  return (
    <>Redirect...</>
  );
});

