import React from 'react';
import usePageVisibility from '../utilities/use-page-visibility';
import {v4} from 'uuid';

export const CartStateContext = React.createContext([]);
export const CartDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
    switch (action.type) {
        case 'add': {
            const entry = state.find(entry => 
                entry.endpointId === action.payload.endpointId 
                && entry.serviceId === action.payload.serviceId);
            return [
                ...(entry ? state.filter(e => e !== entry) : state),
                {...(entry
                    ? {...entry, ...action.payload, quantity: entry.quantity + action.payload.quantity}
                    : {quantity: 1, key: v4(), ...action.payload})}
            ];
        }
        case 'substract': {
            const entry = state.find(entry => 
                entry.endpointId === action.payload.endpointId 
                && entry.serviceId === action.payload.serviceId);
            const nextState = [];
            if (entry) {
                nextState.push(...state.filter(e => e !== entry));
                if (entry.quantity > action.payload.quantity) nextState.push({...entry, ...action.payload, quantity: entry.quantity - action.payload.quantity});
            } else {
                nextState(...state);
            }
            return nextState;
        }
        case 'remove':
            return state.filter((entry) => 
                !(entry.endpointId === action.payload.endpointId 
                && entry.serviceId === action.payload.serviceId));
        case 'bulkRemove':
            return state.filter((entry) => !action.payload.includes(entry.key));
        case 'reset':
            return action.payload;
        default: throw new Error();
    }
};

const cart__ = 'cart__0';
const deserialize = () => 
    window.localStorage.getItem(cart__) && JSON.parse(window.localStorage.getItem(cart__)) || [];
const serialize = (cart) => 
    window.localStorage.setItem(cart__, JSON.stringify(cart));

// const consume = () => {
//     const startTime = Date.now();
//     let result = 0;
//     for (let i = 0; i < 300000000; i++) {
//         result += Math.sqrt(i);
//     }
//     const elapsedTime = Date.now() - startTime;
//     console.log(`Time-consuming function complete. Elapsed time: ${elapsedTime} ms`);
// };

export const CartProvider = React.memo(({children}) => {
    const [state, setState] = React.useState(() => deserialize());
    const onVisibilityChange = React.useCallback((visible) => {
        if (visible) {
            setState(e => reducer(e, {type: 'reset', payload: deserialize()}));
        }
    }, []);
    usePageVisibility(onVisibilityChange);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            // console.log('start CartProvider');
            // consume();
            const nextState = reducer(e, action);
            serialize(nextState);
            // console.log('end CartProvider');
            return nextState;
        });
    }, []);
    return (
        <CartStateContext.Provider value={state}>
            <CartDispatchContext.Provider value={dispatch}>
                {children}
            </CartDispatchContext.Provider>
        </CartStateContext.Provider>
    );
});

export const useCartState = () => React.useContext(CartStateContext);
export const useCartDispatch = () => React.useContext(CartDispatchContext);