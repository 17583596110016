// https://github.com/relay-tools/react-relay-network-modern/blob/master/src/middlewares/auth.js
const isFunction = (obj) => !!(obj && obj.constructor && obj.call && obj.apply);

class RRNLError extends Error {
    constructor(msg) {
        super(msg);
        this.name = 'RRNLError';
    }
}

export class RRNLAuthMiddlewareError extends RRNLError {
    constructor(msg) {
        super(msg);
        this.name = 'RRNLAuthMiddlewareError';
    }
}

export default function authMiddleware(opts) {
  const {
    token: tokenOrThunk,
    tokenRefreshPromise,
    allowEmptyToken = false,
    prefix = 'Bearer ',
    header = 'Authorization',
  } = opts || {};
  let tokenRefreshInProgress = null;
  return (next) => async (req) => {
    const token = await (isFunction(tokenOrThunk) ? tokenOrThunk(req) : tokenOrThunk);
    if (!token && tokenRefreshPromise && !allowEmptyToken) {
        throw new RRNLAuthMiddlewareError('Empty token');
    }
    if (token) {
        req.fetchOpts.headers[header] = `${prefix}${token}`;
    }
    const res = await next(req);
    if (tokenRefreshPromise && res.toString().includes('Access Denied')) {
        if (!tokenRefreshInProgress) {
            tokenRefreshInProgress = Promise.resolve(tokenRefreshPromise(req))
                .then((newToken) => {
                    tokenRefreshInProgress = null;
                    return newToken;
                })
                .catch((err) => {
                    tokenRefreshInProgress = null;
                    throw err;
                });
        }
        return tokenRefreshInProgress.then((newToken) => {
            if (!newToken && !allowEmptyToken) {
                throw new RRNLAuthMiddlewareError('Empty token');
            }
            const newReq = req.clone();
            if (newToken) {
                newReq.fetchOpts.headers[header] = `${prefix}${newToken}`;
            } else {
                delete newReq.fetchOpts.headers[header];
            }
            return next(newReq);
        });
    }
    return res;
  };
}