import React from 'react';
import './application.scss';
import Ogle from '../theme/ogle';
import {Route, Routes} from 'react-router-dom';
import Home from '../home';
import OperatorInterfaceRoutes from '../operator-interface/routes';
import Private from '../private';
import {HelmetProvider, Helmet} from 'react-helmet-async';
import useQuery from '../../utilities/use-query';
import {IntlProvider} from 'react-intl';
import en from '../../../compiled-lang/en.json';
import uk from '../../../compiled-lang/uk.json';
import {ErrorBoundary} from 'react-error-boundary';
import FallbackComponent from '../fallback-component';
import Callback from '../callback';
import SignOut from '../sign-out';
import CheckOut from '../check-out';
import Complete from '../complete';
import ScrollState from '../scroll-state';
import Skeleton from '../skeleton/skeleton';
// import Endpoint from '../endpoint';
import Confirm from '../confirm';
import CookieConsent from '../cookie-consent';
import LogOut from '../log-out';

const locales = {en, uk};

const IntlProviderUrlConnected = React.memo(({children}) => {
  const {locale = 'uk'} = useQuery();
  return (
      <IntlProvider locale={locale} defaultLocale='en' messages={locales[locale]}>
          {children}
      </IntlProvider>
  );
});

const Application = React.memo(() => {
  // const navigate = useNavigate();
  return (
    <>
    <Helmet>
        <title>Welcome to Time to eat</title>
        <meta name='description' content='Web site of your dream'/>
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png'/>
    </Helmet>
    <ScrollState/>
    <CookieConsent/>
    <Ogle>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='operator-interface/*'>
          <Route path='*' element={<OperatorInterfaceRoutes/>} />
        </Route>
        <Route path='/check-out' element={<CheckOut/>}/>
        <Route path='/confirm' element={<Private><Confirm/></Private>}/>
        <Route path='/complete' element={<Private><Complete/></Private>}/>
        {/* <Route path='/endpoint' element={<Endpoint {...{close: () => navigate(-1)}}/>}/> */}
        <Route path='/callback' element={<Callback/>}/>
        <Route path='/sign-out' element={<SignOut/>}/>
        <Route path='/log-out' element={<LogOut/>}/>
      </Routes>
    </Ogle>
    </>
  );
});

export default React.memo(() => (
    <IntlProviderUrlConnected>
      <React.Suspense fallback={
        <div className='mw768-max-width-80rem mw768-margin-0-auto'>
          <div className='padding-left-1dot25rem mw768-padding-left-2rem padding-right-1dot25rem mw768-padding-right-2rem padding-top-1dot25rem mw768-padding-top-2rem padding-bottom-1dot25rem mw768-padding-bottom-2rem'>
            <Skeleton/> 
          </div>
        </div>
      }>
        <ErrorBoundary {...{FallbackComponent}}>
          <Application/>
        </ErrorBoundary>
      </React.Suspense>
    </IntlProviderUrlConnected>
));