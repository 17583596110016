import React from 'react';
import usePageVisibility from '../utilities/use-page-visibility';

export const AuthorizationStateContext = React.createContext({});
export const AuthorizationDispatchContext = React.createContext(() => {});
export const AuthorizationStoreContext = React.createContext({});

export const tokenType__ = 'tokenType';
export const accessToken__ = 'accessToken';
export const refreshToken__ = 'refreshToken';

export const AuthorizationProvider = React.memo(({children, store}) => {
    const [state, setState] = React.useState(!!store.get(tokenType__));
    const onVisibilityChange = React.useCallback((visible) => {
        if (visible) {
            setState(!!store.get(tokenType__));
        }
    }, []);
    usePageVisibility(onVisibilityChange);
    const dispatch = React.useCallback(() => {
        setState(!!store.get(tokenType__));
    }, []);
    return (
        <AuthorizationStoreContext.Provider value={store}>
            <AuthorizationStateContext.Provider value={state}>
                <AuthorizationDispatchContext.Provider value={dispatch}>
                    {children}
                </AuthorizationDispatchContext.Provider>
            </AuthorizationStateContext.Provider>
        </AuthorizationStoreContext.Provider>
    );
});

export const useAuthorizationState = () => React.useContext(AuthorizationStateContext);
export const useAuthorizationDispatch = () => React.useContext(AuthorizationDispatchContext);
export const useAuthorizationStore = () => React.useContext(AuthorizationStoreContext);