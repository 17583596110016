import React from 'react';
import './skeleton.scss';

export default React.memo(() =>
    <div className='skeleton'>
        <div className='skeleton__title'>&nbsp;</div>
        <ul className='skeleton__paragraph'>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
        </ul>
    </div>
);
