const fh = async (method, url, bar) => {
    const RAZZLE_APP_PREFIX = process.env.RAZZLE_APP_PREFIX;
    const response = await fetch(`${RAZZLE_APP_PREFIX}${url}`, {method, body: bar});
    if (!response.ok) {
        throw response; // Not in 200-299 response cpdes
    }
    return response;
};

export const get = async (url, bar) => {
    return await fh('GET', url, bar);
};