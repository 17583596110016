import React from 'react';
import useMounted from './use-mounted';

const useMountedState = (value) => {
    const mounted = useMounted();
    const [state, setState] = React.useState(value);
    const mountedSetState = React.useCallback((value) => {
        mounted.current && setState(value);
    }, [mounted, setState]);
    return [state, mountedSetState];
};

export default useMountedState;