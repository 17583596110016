import React from 'react';

const usePageVisibility = (cb) => {
    const browserApi = () => {
        let hidden, visibilityChange;
        if ('hidden' in document) {
            hidden = 'hidden';
            visibilityChange = 'visibilitychange';
        } else if ('mozHidden' in document) { // Firefox up to v17
            hidden = 'mozHidden';
            visibilityChange = 'mozvisibilitychange';
        } else if ('webkitHidden' in document) { // Chrome up to v32, Android up to v4.4, Blackberry up to v10
            hidden = 'webkitHidden';
            visibilityChange = 'webkitvisibilitychange';
        }
        return {hidden, visibilityChange};
    };
    React.useEffect(() => {
        const {hidden, visibilityChange} = browserApi();
        const handleVisibilityChange = () => {
            cb(!document[hidden]);
        };
        document.addEventListener(visibilityChange, handleVisibilityChange);
        return () => {
            document.removeEventListener(visibilityChange, handleVisibilityChange);
        };
    }, [cb]);
};

export default usePageVisibility;