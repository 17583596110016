import React from 'react';
import useQuery from '../utilities/use-query';
import {useLocation} from 'react-router-dom';
import searchFilter from '../utilities/search-filter';
import classNames from 'classnames';
import {useMoveDispatch} from './move';

export default React.memo(() => {
    const move = useMoveDispatch();
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    const dispatch = React.useCallback((uri) => {
        move();
        setTimeout(() => window.location.href = uri, 500);
    }, []);
    return (
        <div className='display-flex'>
            <div 
                className={classNames('cursor-pointer padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot5rem padding-right-0dot5rem text-sm medium color-gray-700 border-radius-0dot5rem', {
                    'background-color-gray-100': locale === 'uk' 
                })}
                onClick={() => dispatch(
                    `${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`)}
            >УКР</div>
            <div 
                className={classNames('cursor-pointer margin-left-0dot25rem padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot5rem padding-right-0dot5rem text-sm medium color-gray-700 border-radius-0dot5rem', {
                    'background-color-gray-100': locale === 'en'
                })}
                onClick={() => dispatch(
                    `${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`)}
            >ENG</div>
        </div>
    );
});