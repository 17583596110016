import React from 'react';
import {get} from '../utilities/fh';
// import useHydrated from '../utilities/use-hydrated';

const getNodeByDataSrc = (value) => {
    if (typeof document !== 'undefined') {
        return document.querySelector(`img[data-src="${value}"]`);
    }
    return null;
};

const ex = (value) => value !== null && value !== undefined;

const imgCache = {
    __cache: {},
    read(src) {
        const node = getNodeByDataSrc(src);
        if (node) {
            this.__cache[src] = node.getAttribute('src');
            return this.__cache[src];
        }
        if (!ex(this.__cache[src])) {
            this.__cache[src] = new Promise(async (resolve) => {
                try {
                    const foo = await get(`/api/image?url=${src}`);
                    const bar = await foo.text();
                    const baz = src.split('.').pop();
                    const payload = `data:image/${baz};base64,${bar}`;
                    this.__cache[src] = payload;
                    resolve();
                } catch (e) {
                    this.__cache[src] = false;
                    resolve();
                }
            });
        }
        if (this.__cache[src] instanceof Promise) {
            throw this.__cache[src];
        }
        if (!this.__cache[src]) {
            throw new Error();
        }
        return this.__cache[src];
    }
};

export default React.memo((props) => {
    const {src} = props;
    // const hydrated = useHydrated(); if (!src || !hydrated) return <img {...props}/>;
    if (!src) return <img {...props}/>;
    const payload = imgCache.read(src);
    return (
        <>
        {/* <script dangerouslySetInnerHTML={{__html: `window['${escapeUrl(src)}'] = '${payload}'`}}></script> */}
        <img {...{...props, src: payload, 'data-src': props.src}}/>
        </>
    );
});