/**
 * @generated SignedSource<<d9258028495cb1edc0813d0d4d20869f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endpointId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "serviceId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameUk",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "endpointId"
      }
    ],
    "concreteType": "Endpoint",
    "kind": "LinkedField",
    "name": "endpointById",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "serviceId"
      }
    ],
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "serviceById",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkOutPickComponentsEndpointByIdServiceByIdQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkOutPickComponentsEndpointByIdServiceByIdQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "7483cc157ec2c9b53b76980ec56b8d40",
    "id": null,
    "metadata": {},
    "name": "checkOutPickComponentsEndpointByIdServiceByIdQuery",
    "operationKind": "query",
    "text": "query checkOutPickComponentsEndpointByIdServiceByIdQuery(\n  $endpointId: String\n  $serviceId: String\n) {\n  endpointById(id: $endpointId) {\n    id\n    name\n    nameUk\n    price\n  }\n  serviceById(id: $serviceId) {\n    id\n    name\n    nameUk\n  }\n}\n"
  }
};
})();

node.hash = "0f4ca63bae3ade265375d6cad5421221";

module.exports = node;
