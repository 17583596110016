/**
 * @generated SignedSource<<eabf99f4010be895b8fc76198e25f97e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "whoamiNext",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "graphqlWhoamiNextQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "graphqlWhoamiNextQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "09bee2bd5b07b6806ad4e5eadbece440",
    "id": null,
    "metadata": {},
    "name": "graphqlWhoamiNextQuery",
    "operationKind": "query",
    "text": "query graphqlWhoamiNextQuery {\n  whoamiNext {\n    id\n    username\n    email\n  }\n}\n"
  }
};
})();

node.hash = "640c6a447af7ef3eb06fc8df4cd39243";

module.exports = node;
