import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {accessToken__, refreshToken__, tokenType__, useAuthorizationDispatch, useAuthorizationStore} from './authorization';
import {useMoveDispatch} from './move';
import {queryParameters} from '../utilities/qs';
import searchUnFilter from '../utilities/search-un-filter';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;
const RAZZLE_APP_SIGNOUT = process.env.RAZZLE_APP_SIGNOUT;

export default React.memo(() => {
    const authorizationDispatch = useAuthorizationDispatch();
    const authorizationStore = useAuthorizationStore();
    const location = useLocation();
    const locationRef = React.useRef();
    locationRef.current = location;
    const navigate = useNavigate();
    const navigateRef = React.useRef();
    navigateRef.current = navigate;
    const move = useMoveDispatch();
    React.useEffect(() => {
        authorizationStore.remove(tokenType__);
        authorizationStore.remove(accessToken__);
        authorizationStore.remove(refreshToken__);
        authorizationDispatch();
        move();
        const qps = queryParameters(locationRef.current);
        setTimeout(() => window.location.href = RAZZLE_APP_ACCOUNTS + '/' + RAZZLE_APP_SIGNOUT
            + '?cache=0&scope=Users:.* Recurrings:.*' 
            + `&redirect=${document.location.origin}/callback`
            + '&continue=' + encodeURIComponent('/' + searchUnFilter(document.location.search))
            + (qps.length ? '&' + qps.join('&') : ''),
            500);
    }, []);
    return (
        <>
            Log out...
        </>
    );
});