import React from 'react';

export const MoveDispatchContext = React.createContext(() => {});

export const MoveProvider = React.memo(({children}) => {
    const [move, setMove] = React.useState();
    const dispatch = React.useCallback(() => {
        setMove(true);
    }, []);
    return (
        <MoveDispatchContext.Provider value={dispatch}>
            {move ? <>Moving...</> : <>{children}</>}
        </MoveDispatchContext.Provider>
    );
});

export const useMoveDispatch = () => React.useContext(MoveDispatchContext);