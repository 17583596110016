/**
 * @generated SignedSource<<311c140881be3b862f400aa893385adc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "serviceById",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "merchant",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkServiceStateServiceByIdQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkServiceStateServiceByIdQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "edffc261e35582ae8b388c56121dc7ca",
    "id": null,
    "metadata": {},
    "name": "checkServiceStateServiceByIdQuery",
    "operationKind": "query",
    "text": "query checkServiceStateServiceByIdQuery(\n  $id: String\n) {\n  serviceById(id: $id) {\n    id\n    version\n    description\n    merchant {\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "24714f28c169fcdac1b6eb7a9e9bf3d5";

module.exports = node;
