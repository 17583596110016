import React from 'react';
import {useLocation} from 'react-router-dom';
import {queryParameters} from '../utilities/qs';
import searchUnFilter from '../utilities/search-un-filter';
import {useMoveDispatch} from './move';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;

export default React.memo(() => {
    const move = useMoveDispatch();
    const location = useLocation();
    const locationRef = React.useRef();
    locationRef.current = location;
    React.useEffect(() => {
        move();
        const qps = queryParameters(locationRef.current);
        setTimeout(() => window.location.href = RAZZLE_APP_ACCOUNTS 
            + '/scope?cache=0&scope=Users:.* Recurrings:.*' 
            + `&redirect=${document.location.origin}/callback`
            + '&continue=' + encodeURIComponent(locationRef.current.pathname + searchUnFilter(document.location.search))
            + (qps.length ? '&' + qps.join('&') : ''), 
            500);
    }, []);
    return (
        <>Sign in...</>
    );
});
