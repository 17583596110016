/**
 * @generated SignedSource<<e888ec4bf4fc158044be22d9e9a93ae1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "LandResponse",
    "kind": "LinkedField",
    "name": "landImpulse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Land",
        "kind": "LinkedField",
        "name": "lands",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PickEx",
            "kind": "LinkedField",
            "name": "pickExs",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "index",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Solve",
        "kind": "LinkedField",
        "name": "solves",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkOutQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkOutQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5c9b47353e85cf9aaa177d71e70e106b",
    "id": null,
    "metadata": {},
    "name": "checkOutQuery",
    "operationKind": "query",
    "text": "query checkOutQuery(\n  $request: LandRequestInput\n) {\n  landImpulse(request: $request) {\n    lands {\n      service\n      pickExs {\n        index\n      }\n    }\n    solves {\n      amount\n    }\n  }\n}\n"
  }
};
})();

node.hash = "d82693686620e8f7a914959754c442ff";

module.exports = node;
