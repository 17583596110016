import React from 'react';
import classNames from 'classnames';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';

const Amount = React.memo(({cartState, flag}) => {
    const request = React.useMemo(() => ({
        picks: cartState.map((e) => ({endpoint: e.endpointId, service: e.serviceId, quantity: e.quantity}))
    }), [cartState]);
    const {landImpulse} = useLazyLoadQuery(
        graphql`
            query amountLandImpulseQuery($request: LandRequestInput) {
                landImpulse(request: $request) {
                    lands {
                        pickExs {
                            index
                        }
                    }
                    solves {
                        amount
                    }
                }
            }
        `,
        {request}
    );
    return (
        <span className={classNames('padding-left-0dot5rem', {'opacity-0dot6': flag})}>
            {landImpulse.solves[0]?.amount} ₴
        </span>
    );
});

export default React.memo(Amount);