import React from 'react';
import usePageVisibility from '../utilities/use-page-visibility';
import {v4} from 'uuid';

export const ClaimStateContext = React.createContext([]);
export const ClaimDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return [{key: v4(), ...action.payload}];
        case 'remove':
            return state.filter((entry) => entry.id !== action.payload.id);
        case 'reset':
            return action.payload;
        case 'update':
            const updatable = state.find(e => e.id === action.payload.id);
            if (updatable) {
                return [...state.filter((entry) => entry.id !== action.payload.id), {...updatable, successPageVisited: action.payload.successPageVisited}];
            } else {
                return state;
            }
        default: throw new Error();
    }
};

const claim__ = 'claim__2';
const deserialize = () => 
    window.localStorage.getItem(claim__) && JSON.parse(window.localStorage.getItem(claim__)) || [];
const serialize = (claim) => 
    window.localStorage.setItem(claim__, JSON.stringify(claim));

export const ClaimProvider = React.memo(({children}) => {
    const [state, setState] = React.useState(() => deserialize());
    const onVisibilityChange = React.useCallback((visible) => {
        if (visible) {
            setState(e => reducer(e, {type: 'reset', payload: deserialize()}));
        }
    }, []);
    usePageVisibility(onVisibilityChange);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            serialize(nextState);
            return nextState;
        });
    }, []);
    return (
        <ClaimStateContext.Provider value={state}>
            <ClaimDispatchContext.Provider value={dispatch}>
                {children}
            </ClaimDispatchContext.Provider>
        </ClaimStateContext.Provider>
    );
});

export const useClaimState = () => React.useContext(ClaimStateContext);
export const useClaimDispatch = () => React.useContext(ClaimDispatchContext);