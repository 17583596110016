/**
 * @generated SignedSource<<bbdb7f56813bcbb2d104016ca1ae3e05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "endpointCountComponents_endpoint",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Endpoint",
  "abstractKey": null
};

node.hash = "cbc43597aaa14bcc06b237fc9cb7cf11";

module.exports = node;
