import React from 'react';
import {v4} from 'uuid';
import usePageVisibility from '../../utilities/use-page-visibility';
import moment from 'moment';

export const OperatorStateContext = React.createContext({completedReports: {}});
export const OperatorDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
    switch (action.type) {
        case 'start':
            return {...state, [action.payload.serviceId]: {key: v4(), endpoints: {}, stockListIds: [], completed: false}};
        case 'update-endpoint':
            return {...state, [action.payload.serviceId]: {...state[action.payload.serviceId], endpoints: {...state[action.payload.serviceId].endpoints, [action.payload.endpoint.id]: {...action.payload.endpoint, key: v4()}}}};
        case 'set-stock-list':
            return {...state, [action.payload.serviceId]: {...state[action.payload.serviceId], stockListIds: [...action.payload.stockListIds]}}
        case 'complete':
            const reports = state.completedReports[action.payload.serviceId] || [];
            const newReports = [{...state[action.payload.serviceId], completed: true, date: moment().toISOString()}];
            if (reports.length > 3) {
                newReports.push(...reports.slice(0, 2));
            } else {
                newReports.push(...reports);
            }
            return {...state, [action.payload.serviceId]: {key: v4(), endpoints: {}, stockListIds: [], completed: false}, completedReports: {...state.completedReports, [action.payload.serviceId]: newReports}};
        case 'reset':
            return action.payload;
        default: throw new Error();
    }
};

const operator__ = 'operator__1';
const deserialize = () => window.localStorage.getItem(operator__) && JSON.parse(window.localStorage.getItem(operator__)) || {completedReports: {}};
const serialize = (operator) => window.localStorage.setItem(operator__, JSON.stringify(operator));

export const OperatorProvider = React.memo(({children}) => {
    const [state, setState] = React.useState(() => deserialize());
    const onVisibilityChange = React.useCallback((visible) => {
        if (visible) {
            setState(e => reducer(e, {type: 'reset', payload: deserialize()}));
        }
    }, []);
    usePageVisibility(onVisibilityChange);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            serialize(nextState);
            return nextState;
        });
    }, []);
    return (
        <OperatorStateContext.Provider value={state}>
            <OperatorDispatchContext.Provider value={dispatch}>
                {children}
            </OperatorDispatchContext.Provider>
        </OperatorStateContext.Provider>
    );
});

export const useOperatorState = () => React.useContext(OperatorStateContext);
export const useOperatorDispatch = () => React.useContext(OperatorDispatchContext);
