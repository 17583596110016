/**
 * @generated SignedSource<<4a9bb4b85c94acc4640392272d13a10e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Endpoint",
    "kind": "LinkedField",
    "name": "updateEndpointSeason",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "strategy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "every",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "step8OperatorInterfaceEndpointSeasonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "step8OperatorInterfaceEndpointSeasonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3106abbbf448f8943bccaea0255e34ec",
    "id": null,
    "metadata": {},
    "name": "step8OperatorInterfaceEndpointSeasonMutation",
    "operationKind": "mutation",
    "text": "mutation step8OperatorInterfaceEndpointSeasonMutation(\n  $request: UpdateEndpointSeasonRequestInput\n) {\n  updateEndpointSeason(request: $request) {\n    id\n    version\n    start\n    end\n    strategy\n    every\n  }\n}\n"
  }
};
})();

node.hash = "d40f7c48661af5d523c9aafe85677288";

module.exports = node;
