import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../utilities/search-filter';

export default React.memo(({error}) => {
  const location = useLocation();
  if (error?.toString()?.includes('Access Denied')) {
    return (
      <>
        Looks your token is expired. <Link to={`/sign-out${searchFilter(location.search)}`}>Please sign in again.</Link>
      </>
    );
  }
  throw error;
});

