import React from 'react';
import useQuery from './use-query';
import {useBuffetState} from '../components/buffet';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const useBuffetStateEx = () => {
    const {serviceId = serviceIdEx} = useQuery();
    const buffetState = useBuffetState();
    const cs = React.useMemo(
        () =>  buffetState.filter(e => e.serviceId === serviceId),
        [buffetState, serviceId]
    );
    return cs;
};

export default useBuffetStateEx;