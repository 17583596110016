/**
 * @generated SignedSource<<860a60765bab3cbf47618cec7394bf3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "homeCardComponents_serviceById",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "endpointComponents_serviceById"
    }
  ],
  "type": "Service",
  "abstractKey": null
};

node.hash = "3284d674961403c90705de8a24b82e0b";

module.exports = node;
