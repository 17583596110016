import React from 'react';
import SolidTickIcon from '../../assets/icons0/solid-tick.svg';
import {useLocation} from 'react-router-dom';
import classNames from 'classnames';

const STEPS = [{
  contains: '3'
}, {
  contains: '5'
}, {
  contains: '8'
}];

const StepsBar = () => {
  const location = useLocation();
  const currentStep = location.pathname.split('/step-')[1].replace('/', '');
  const activeStepIndex = STEPS.findIndex(step => currentStep.includes(step.contains));
  return (
    <div className='steps-ui'>
      {STEPS.map((item, index) => 
        <div className={classNames('step-ui', {'step-ui--active': activeStepIndex === index, 'step-ui--completed': activeStepIndex > index})} key={index}>
          <div className='step-ui--icon-wrapper'>
            <div className='step-ui--icon'>
              <SolidTickIcon className='height-1rem width-1rem display-block'/>  
            </div>
            <div className='step-ui--bar-wrapper'>
              <div className='step-ui--bar'></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(StepsBar);