import React from "react";

const DarkMessage = ({title, message, style, children}) => {
    return (
        <div className='message-box background-color-gray-800 padding-1rem border-radius-0dot75rem' style={style}>
            <p className='text-md semibold color-white'>{title}</p>
            <p className='text-sm color-gray-300'>
                {message}
            </p>
            <div className='padding-top-0dot75rem'>
                {children}
            </div>
        </div>
    )
}
export default React.memo(DarkMessage);