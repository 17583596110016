// https://github.com/apollographql/subscriptions-transport-ws/blob/c6fb351fe3d7c7a447438798fab43c268b33036e/src/client.ts
const _global = typeof global !== 'undefined' ? global : (typeof window !== 'undefined' ? window : {});
const NativeWebSocket = _global.WebSocket || _global.MozWebSocket;

import * as Backoff from 'backo2';
import EventEmitter from 'eventemitter3';
const _START = 'subscribe';
const _DATA = 'data';
const _PING = 'ping';
const _PONG = 'pong';
const _STOP = 'unsubscribe';

export class Subscription {
    client;
    operations;
    url;
    nextOperationId;
    unsentMessagesQueue;
    reconnect;
    reconnectionAttempts;
    backoff;
    eventEmitter;
    lazy;
    inactivityTimeout;
    inactivityTimeoutId;
    wsImpl;
    tryReconnectTimeoutId;
    pingPongTimeout;
    pingPongTimeoutId;
    inGame;

    constructor(url, options, webSocketImpl) {
        const {
            reconnect = false,
            reconnectionAttempts = Infinity,
            lazy = false,
            inactivityTimeout = 0,
            pingPongTimeout = 360 * 1000
        } = (options || {});
        this.wsImpl = webSocketImpl || NativeWebSocket;
        if (!this.wsImpl) {
            throw new Error('Unable to find native implementation, or alternative implementation for WebSocket!');
        }
        this.url = url;
        this.operations = {};
        this.nextOperationId = 0;
        this.unsentMessagesQueue = [];
        this.reconnect = reconnect;
        this.reconnectionAttempts = reconnectionAttempts;
        this.lazy = !!lazy;
        this.inactivityTimeout = inactivityTimeout;
        this.pingPongTimeout = pingPongTimeout;
        this.backoff = new Backoff({jitter: 0.5});
        this.eventEmitter = new EventEmitter();
        this.client = null;
        this.inGame = null;
        if (!this.lazy) {
            this.connect();
        }
    }

    get status() {
        if (this.client === null) {
            return this.wsImpl.CLOSED;
        }
        return this.client.readyState;
    }

    close(isForced = true) {
        this.clearPingPongTimeout();
        this.clearInactivityTimeout();
        this.flushUnsentMessagesQueue();
        if (isForced) {
            this.clearTryReconnectTimeout();
            this.backoff.reset();
        }
        if (this.client !== null) {
            this.client.onopen = null;
            this.client.onclose = null; // disable onclose handler first
            this.client.onerror = null;
            this.client.onmessage = null;
            this.client.close(); // be careful, causes synchronous onclose to call this.close()
            this.client = null;
            this.eventEmitter.emit('disconnected');
            if (!isForced) {
                this.tryReconnect();
            }
        }
    }

    request(request) {
        const executeOperation = this.executeOperation.bind(this);
        const unsubscribe = this.unsubscribe.bind(this);
        let opId;
        this.clearInactivityTimeout();
        return {
            subscribe(onNext, onError) {
                opId = executeOperation(request, (error, result) => {
                    if (error) {
                        onError && onError(error);
                    } else {
                        onNext && onNext(result);
                    }
                });
                return {
                    unsubscribe: () => {
                        if (opId) {
                            unsubscribe(opId);
                            opId = null;
                        }
                    }
                };
            },
        };
    }

    on(eventName, callback, context) {
        const handler = this.eventEmitter.on(eventName, callback, context);
        return () => {
            handler.off(eventName, callback, context);
        };
    }

    onConnected(callback, context) {
        return this.on('connected', callback, context);
    }

    onDisconnected(callback, context) {
        return this.on('disconnected', callback, context);
    }

    onError(callback, context) {
        return this.on('error', callback, context);
    }

    executeOperation(options, handler) {
        if (this.client === null) {
            this.connect();
        }
        const opId = this.generateOperationId();
        this.operations[opId] = {options: options, handler};
        this.sendMessage(opId, _START, options);
        return opId;
    }

    clearTryReconnectTimeout() {
        if (this.tryReconnectTimeoutId) {
            clearTimeout(this.tryReconnectTimeoutId);
            this.tryReconnectTimeoutId = null;
        }
    }

    clearInactivityTimeout() {
        if (this.inactivityTimeoutId) {
            clearTimeout(this.inactivityTimeoutId);
            this.inactivityTimeoutId = null;
        }
    }

    setInactivityTimeout() {
        this.inactivityTimeoutId = setTimeout(() => {
            if (Object.keys(this.operations).length === 0) {
                this.close();
            }
        }, this.inactivityTimeout);
    }

    buildMessage(opId, type, payload) {
        return {opId, type, payload};
    }

    formatErrors(errors) {
        if (Array.isArray(errors)) {
            return errors;
        }
        if (errors && errors.errors) {
            return this.formatErrors(errors.errors);
        }
        if (errors && errors.message) {
            return [errors];
        }
        return [{
            name: 'FormattedError',
            message: 'Unknown error',
            originalError: errors,
        }];
    }

    sendMessage(opId, type, payload) {
        this.sendMessageRaw(this.buildMessage(opId, type, payload));
    }

    sendMessageRaw(message) {
        switch (this.status) {
            case this.wsImpl.OPEN:
                const serializedMessage = JSON.stringify(message);
                try {
                    JSON.parse(serializedMessage);
                } catch (e) {
                    this.eventEmitter.emit('error', `Message must be JSON-serializable. Got: ${message}`);
                }
                this.client.send(serializedMessage);
                break;
            case this.wsImpl.CONNECTING:
                this.unsentMessagesQueue.push(message);
                break;
            default:
                this.eventEmitter.emit('error', 'A message was not sent because socket is not connected, is closing or ' +
                    'is already closed. Message was: ' + JSON.stringify(message));
        }
    }

    generateOperationId() {
        return String(++this.nextOperationId);
    }

    tryReconnect() {
        if (!this.reconnect || this.backoff.attempts >= this.reconnectionAttempts) {
            return;
        }
        Object.keys(this.operations).forEach((key) => {
            this.unsentMessagesQueue.push(
                this.buildMessage(key, _START, this.operations[key].options),
            );
        });
        this.clearTryReconnectTimeout();
        const delay = this.backoff.duration();
        this.tryReconnectTimeoutId = setTimeout(() => {
            this.connect();
        }, delay);
    }

    flushUnsentMessagesQueue() {
        this.unsentMessagesQueue.forEach((message) => {
            this.sendMessageRaw(message);
        });
        this.unsentMessagesQueue = [];
    }

    clearPingPongTimeout() {
        if (this.pingPongTimeoutId) {
            clearTimeout(this.pingPongTimeoutId);
            this.pingPongTimeoutId = null;
        }
    }

    pingPong () {
        this.pingPongTimeoutId = setTimeout(() => {
            if (!this.inGame) {
                this.close(false);
                return;
            }
            this.inGame = false;
            this.sendMessageRaw({type: _PING});
            this.pingPong();
        }, this.pingPongTimeout);
    }

    connect() {
        this.client = new this.wsImpl(this.url);
        this.client.onopen = () => {
            this.eventEmitter.emit('connected');
            this.flushUnsentMessagesQueue();
            this.backoff.reset();
            this.inGame = true;
            this.pingPong();
        };
        this.client.onclose = () => {
            this.close(false);
        };
        this.client.onerror = (err) => {
            this.eventEmitter.emit('error', err);
        };
        this.client.onmessage = ({data}) => {
            this.processReceivedData(data);
        };
    }

    processReceivedData(receivedData) {
        let parsedMessage;
        try {
            parsedMessage = JSON.parse(receivedData);
        } catch (e) {
            this.eventEmitter.emit('error', `Message must be JSON-parseable. Got: ${receivedData}`);
            return;
        }
        if (parsedMessage.type === _PONG) {
            this.inGame = true;
            return;
        }
        const opId = parsedMessage.opId;
        if (!this.operations[opId]) {
            this.eventEmitter.emit('error', `There is no such operation registered. Got opId/parsedMessage: ${opId}/${receivedData}`);
            return;
        }
        switch (parsedMessage.type) {
            case _DATA:
                const parsedPayload = !parsedMessage.payload.errors ?
                    parsedMessage.payload : {...parsedMessage.payload, errors: this.formatErrors(parsedMessage.payload.errors)};
                this.operations[opId].handler(null, parsedPayload);
                break;
            default:
                this.eventEmitter.emit('error',
                    `There is no such message type. Got type/parsedMessage: ${parsedMessage.type}${receivedData}`);
        }
    }

    unsubscribe(opId) {
        if (this.operations[opId]) {
            delete this.operations[opId];
            this.setInactivityTimeout();
        }
        this.sendMessage(opId, _STOP, undefined);
    }
}
