import React from 'react';
import {useErrorMessagesState, useErrorMessagesDispatch} from './error-messages-context';
import XLogo from '../../assets/icons/x.svg';
import AlertCircle from '../../assets/icons0/alert-circle.svg';
import './error-messages.scss';

const ErrorMessage = ({title, message, action, closeError, additionalMessage}) => {
    return (
        <div className='error-message-container padding-1rem background-color-error-25 border-1px-solid border-color-error-300 border-radius-0dot75rem position-relative' data-gtm='error'>
            <div className='position-absolute top-1rem left-1rem'>
                <AlertCircle className='display-block width-1dot5rem height-1dot5rem error-message-alert-icon'/>
            </div>
            <div className='position-absolute top-1dot25rem right-1rem' onClick={closeError} data-gtm='error-btn close'>
                <XLogo className='display-block width-1rem height-1rem color-error-500 cursor-pointer'/>
            </div>
            <div className='color-error-700 padding-left-2dot5rem text-sm'>
                <p className='semibold padding-top-0dot125rem'>
                    {title}
                </p>
                <p className='color-error-600 padding-top-0dot625rem'>
                    {message}
                </p>
                <div className='padding-top-0dot75rem display-flex'>
                    {action}
                </div>
                {additionalMessage && <p className='padding-top-0dot75rem additional-message color-error-400 word-break-break-all'>{additionalMessage}</p>}
            </div>
        </div>
    )
};

const ErrorMessages = React.memo(() => {
    const state = useErrorMessagesState();
    const dispatch = useErrorMessagesDispatch();
    
    return (
        <div className='position-fixed top-1rem right-1rem z-index-2' data-gtm='errors-box'>
            {state.map((item) => (
                <ErrorMessage key={item.key} {...{...item}} closeError={() => dispatch({type: 'remove', payload: item.key})}/>
            ))}
        </div>
    )
});

export default React.memo(ErrorMessages);