import React from 'react';

const useNow = () => {
    const [now, setNow] = React.useState(new Date());
    React.useEffect(() => {
        let timer;
        const lambda = () => {
            timer = setTimeout(() => {
                setNow(new Date());
                lambda();
            }, 1000);
        };
        lambda();
        return () => clearTimeout(timer);
    }, [setNow]);
    return now;
};

export default useNow;