import qs from 'qs';

export const exists = (location, parameter) =>
    qs.parse(location.search, {ignoreQueryPrefix: true})[parameter] !== undefined &&
    qs.parse(location.search, {ignoreQueryPrefix: true})[parameter] !== null;

export const parse = (location, parameter) => qs.parse(location.search, {ignoreQueryPrefix: true})[parameter];

export const parseEx = (search, parameter) => qs.parse(search, {ignoreQueryPrefix: true})[parameter];

export const queryParameters = (location) => {
    const qps = [];
    exists(location, 'locale') && qps.push('locale=' + parse(location, 'locale'));
    exists(location, 'currency') && qps.push('currency=' + parse(location, 'currency'));
    exists(location, 'apply') && qps.push('apply=' + parse(location, 'apply'));
    return qps;
};
