import React from 'react';
import {v4} from 'uuid';

export const ErrorMessagesStateContext = React.createContext([]);
export const ErrorMessagesDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return [{key: v4(), ...action.payload}, ...state];
        case 'remove':
            return state.filter((entry) => entry.key !== action.payload);
        case 'reset':
            return action.payload;
        default: throw new Error();
    }
};

export const ErrorMessagesProvider = React.memo(({children}) => {
    const [state, setState] = React.useState([]);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            return nextState;
        });
    }, []);
    return (
        <ErrorMessagesStateContext.Provider value={state}>
            <ErrorMessagesDispatchContext.Provider value={dispatch}>
                {children}
            </ErrorMessagesDispatchContext.Provider>
        </ErrorMessagesStateContext.Provider>
    );
});

export const useErrorMessagesState = () => React.useContext(ErrorMessagesStateContext);
export const useErrorMessagesDispatch = () => React.useContext(ErrorMessagesDispatchContext);
