import React from 'react';
import usePageVisibility from '../utilities/use-page-visibility';
import {v4} from 'uuid';

export const BuffetStateContext = React.createContext([]);
export const BuffetDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return [{key: v4(), ...action.payload}];
        case 'remove':
            return state.filter((entry) => entry.id !== action.payload.id);
        case 'reset':
            return action.payload;
        default: throw new Error();
    }
};

const buffet__ = 'buffet__3';
const deserialize = () => 
    window.localStorage.getItem(buffet__) && JSON.parse(window.localStorage.getItem(buffet__)) || [];
const serialize = (buffet) => 
    window.localStorage.setItem(buffet__, JSON.stringify(buffet));

export const BuffetProvider = React.memo(({children}) => {
    const [state, setState] = React.useState(() => deserialize());
    const onVisibilityChange = React.useCallback((visible) => {
        if (visible) {
            setState(e => reducer(e, {type: 'reset', payload: deserialize()}));
        }
    }, []);
    usePageVisibility(onVisibilityChange);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            serialize(nextState);
            return nextState;
        });
    }, []);
    return (
        <BuffetStateContext.Provider value={state}>
            <BuffetDispatchContext.Provider value={dispatch}>
                {children}
            </BuffetDispatchContext.Provider>
        </BuffetStateContext.Provider>
    );
});

export const useBuffetState = () => React.useContext(BuffetStateContext);
export const useBuffetDispatch = () => React.useContext(BuffetDispatchContext);