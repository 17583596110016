/**
 * @generated SignedSource<<8dbe6dc859cbe0ff8ded72eb6fbe33b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "claimRequest"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "claimRequest"
      }
    ],
    "concreteType": "Impulse",
    "kind": "LinkedField",
    "name": "impulseByClaim",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "claim",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "endpointImpulseByClaimQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "endpointImpulseByClaimQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c5c7bfab2792a1c9d85172d6cbdfa4cb",
    "id": null,
    "metadata": {},
    "name": "endpointImpulseByClaimQuery",
    "operationKind": "query",
    "text": "query endpointImpulseByClaimQuery(\n  $claimRequest: ClaimRequestInput\n) {\n  impulseByClaim(request: $claimRequest) {\n    id\n    status\n    claim\n  }\n}\n"
  }
};
})();

node.hash = "b0d3efe44693993638f560df2845d350";

module.exports = node;
