/**
 * @generated SignedSource<<b065c1cac8a3fed6973a05d8688e545e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "endpointComponents_serviceById",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "endpointCountComponents_serviceById"
    }
  ],
  "type": "Service",
  "abstractKey": null
};

node.hash = "396f7e87aef1f802b9b638b83f5f7fd9";

module.exports = node;
