/**
 * @generated SignedSource<<a70e6449c78ef4ee03f8970019eb4f8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "impulseRequest"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "impulseRequest",
        "variableName": "impulseRequest"
      }
    ],
    "concreteType": "Impulse",
    "kind": "LinkedField",
    "name": "createImpulse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "claim",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkOutCreateComponentsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkOutCreateComponentsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "47596f590cf0dfa8d3a609ec71af216d",
    "id": null,
    "metadata": {},
    "name": "checkOutCreateComponentsMutation",
    "operationKind": "mutation",
    "text": "mutation checkOutCreateComponentsMutation(\n  $impulseRequest: ImpulseRequestInput!\n) {\n  createImpulse(impulseRequest: $impulseRequest) {\n    id\n    claim\n  }\n}\n"
  }
};
})();

node.hash = "7c0c86dbd50f7b38c4da31fb8ec3a22a";

module.exports = node;
