import React from 'react';
import useQuery from '../../utilities/use-query';
import {useOperatorState} from './operator-interface';
import Redirect from '../redirect';
import searchFilter from '../../utilities/search-filter';
import {Outlet, useLocation} from 'react-router-dom';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const CheckReportStatus = () => {
    const {reportIndex, serviceId = serviceIdEx} = useQuery();
    const location = useLocation();
    const currentStep = location.pathname.split('/step-')[1]?.replace('/', '');
    if (reportIndex) {
        const report = useOperatorState().completedReports[serviceId][reportIndex];
        const location = useLocation();
        if (!report) {
            return <Redirect to={`/${searchFilter(location.search, {reportIndex})}`}/>
        }
        if (report && currentStep !== '8') {
            return <Redirect to={`../step-8${searchFilter(location.search, {reportIndex})}`}/>
        }
    }
    return (
        <Outlet/>
    )
}

export default React.memo(CheckReportStatus);