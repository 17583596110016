import React from 'react';
import useQuery from './use-query';
import {useClaimState} from '../components/claim';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const useClaimStateEx = () => {
    const {serviceId = serviceIdEx} = useQuery();
    const cartState = useClaimState();
    const cs = React.useMemo(
        () =>  cartState.filter(e => e.serviceId === serviceId),
        [cartState, serviceId]
    );
    return cs;
};

export default useClaimStateEx;