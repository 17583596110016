import React from 'react';
import {useCartState} from '../components/cart';
import useQuery from './use-query';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const useCartStateEx = () => {
    const {serviceId = serviceIdEx} = useQuery();
    const cartState = useCartState();
    const cs = React.useMemo(
        () =>  cartState.filter(e => e.serviceId === serviceId),
        [cartState, serviceId]
    );
    return cs;
};

export default useCartStateEx;