/**
 * @generated SignedSource<<98d85c8e70f3249e1c045da84f2bd9e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "Endpoint",
    "kind": "LinkedField",
    "name": "endpointsByIds",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "quantity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Service",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "cartCheckEndpointsByIdsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cartCheckEndpointsByIdsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2b3a39b39a25aee27318b5db6b3fc840",
    "id": null,
    "metadata": {},
    "name": "cartCheckEndpointsByIdsQuery",
    "operationKind": "query",
    "text": "query cartCheckEndpointsByIdsQuery(\n  $ids: [String]\n) {\n  endpointsByIds(ids: $ids) {\n    id\n    quantity\n    end\n    services {\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "122ae27a0cb959cfaa77823807860956";

module.exports = node;
